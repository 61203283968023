
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisplayPriceInfo } from '../../models/Product';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';

@Component({
    name: 'DetailsSuggestedRetailPrice',
    components: {
    },
})
export default class DetailsSuggestedRetailPrice extends Vue {
    @Prop({ type: String, required: true }) srpText: string;
    @Prop({ type: String, required: true }) incvatText: string;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get loading(): boolean {
        return ProductDetailsModule.IS_LOADING;
    }

    get displayPriceInfo(): DisplayPriceInfo {
        if (ProductDetailsModule.VARIANTS?.length > 0)
            return ProductDetailsModule.SELECTED_VARIANT?.suggestedretailprice;
        else 
            return ProductDetailsModule.PRODUCT?.suggestedretailprice;
    }

    get price(): string {
        return this.displayPriceInfo.priceincvat;
    }  
}
